<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition-opacity duration-100 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div
        v-show="delayedShowModal"
        id="backdrop"
        :aria-hidden="showModal"
        class="absolute top-0 bottom-0 left-0 right-0 z-50 bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-md"
        @click.self="$emit('closeModal');"
      >
        <div
          ref="mainContainer"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="dialog_header"
          :class="{
            'max-w-[1200px]': size === 'fullscreen',
            'max-w-4xl': size === 'large',
            'max-w-3xl': size === 'medium',
            'max-w-xl': size === 'small',
            'max-w-lg': size === 'xs',
            'max-w-md': size === '2xs',
            'max-w-sm': size === '3xs',
          }"
          tabindex="0"
          class="absolute max-h-[calc(100vh-100px)] w-[90%] p-3 text-left align-middle transition-all transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl left-1/2 top-1/2 rounded-2xl"
          :style="fixedHeight ? { height: fixedHeight } : {}"
        >
          <button
            class="absolute text-white border-2 rounded-full -right-6 -top-6 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 aspect-square"
            @click="$emit('closeModal');"
          >
            <span class="sr-only">Close</span>
            <div class="w-5 p-px ">
              <IconResolver icon-name="x" />
            </div>
          </button>
          <main
            class="flex flex-col bg-white max-h-[calc(100vh-148px)]  "
            style="height:100%"
          >
            <div>
              <div
                id="dialog_header"
                class="mx-3 mt-3"
              >
                <slot name="header" />
              </div>
            </div>
            <div class="overflow-y-auto">
              <div
                class="flex-1 max-h-full mx-3 "
              >
                <slot name="body" />
              </div>
            </div>
            <div class="">
              <div class="mx-3 mb-3">
                <slot name="footer" />
              </div>
            </div>
          </main>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">

type Props = {
  showModal: boolean;
  size?: 'fullscreen' | 'large' | 'medium' | 'small' | 'xs' | '2xs' | '3xs';
  fixedHeight?: string;
};
const props = withDefaults(defineProps<Props>(),{
  size: 'medium',
});

const mainContainer = ref<HTMLElement | null>(null);

const emit  = defineEmits<{
  (event: 'closeModal'): void;
}>();

const cleanup = useEventListener(document, 'keydown', (e) => {
  if(props.showModal){
    if(e.key === 'Escape'){
      emit('closeModal')
    }
  }
})

onBeforeUnmount(() => {
  cleanup()
})

const delayedShowModal = ref(false);

watch(
  () => props.showModal,
  (value) => {
    setTimeout(() => {
      delayedShowModal.value = value;
    }, 1);
  }
);
</script>
